<template>
  <el-tooltip :content="value" placement="top-start">
    <div class="tip-des" ref="content" @mouseover="isShowTooltip">{{ value }}</div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'DescriptionsTooltip',
  props: ['value'],
  data() {
    return {
      tooltipShow: true
    };
  },
  methods: {
    isShowTooltip() {
      const bool = this.$refs.content.offsetWidth < this.$refs.content.parentNode.offsetWidth;
      this.tooltipShow = true;
    }
  }
};
</script>

<style scoped>
.tip-des {
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
}
</style>

<template>
  <el-dialog :title="title" :visible="dialogVisible" width="40%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="品类编号">
        <el-input v-model="form.productCode" :maxlength="10" placeholder="非必填" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="品类名称">
        <el-input v-model="form.name" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="品类">
        <el-select v-model="form.resourceType" placeholder="请选择" :disabled="isDisable">
          <el-option v-for="item in option3" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格">
        <el-select v-model="form.unitOfMeasurement" placeholder="请选择" :disabled="isDisable">
          <el-option v-for="item in option2" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单位">
        <el-select v-model="form.unitSpecs" placeholder="请选择" :disabled="isDisable">
          <el-option v-for="item in option1" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="单位数量">
        <el-input-number v-model="form.countPerUnit" :disabled="isDisable" :min="0"></el-input-number>
        <span style="margin-left: 12px">
          {{ uniDetail }}
        </span>
      </el-form-item>
      <el-form-item label="预计采购价">
        <el-input-number
          v-model="form.recommendedPrice"
          :precision="2"
          :controls="false"
          :disabled="isDisable"
          oninput="value=value.replace(/[^0-9.]/g,'')"
        ></el-input-number>
        元
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" autosize v-model="form.descriptions" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button v-if="!isDisable" type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button v-if="!isDisable" @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { addProduction, editProduction } from '../api/api';

export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    isDisable: false,
    currentItem: null
  },
  components: {},
  computed: {
    title() {
      if (this.isDisable) {
        return '详情';
      }
      if (this.isEdit) {
        return '编辑';
      }
      return '新建';
    },
    uniDetail() {
      if (this.form.countPerUnit && this.form.unitSpecs && this.form.unitOfMeasurement) {
        let val1, val2;
        this.option1.forEach((e) => {
          if (e.id == this.form.unitSpecs) {
            val1 = e.name;
          }
        });
        this.option2.forEach((e) => {
          if (e.id == this.form.unitOfMeasurement) {
            val2 = e.name;
          }
        });
        return `${this.form.countPerUnit}${val1}/${val2}`;
      }
      return '';
    }
  },
  data() {
    return {
      form: {
        unitSpecs: '',
        unitOfMeasurement: '',
        descriptions: '',
        name: '',
        productCode: '',
        countPerUnit: '',
        resourceType: '',
        recommendedPrice: ''
      },
      options: [],
      option3: [],
      option2: [],
      option1: []
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.form = {
          unitSpecs: '',
          unitOfMeasurement: '',
          descriptions: '',
          name: '',
          productCode: '',
          countPerUnit: '',
          resourceType: '',
          recommendedPrice: ''
        };
        if (this.isEdit) {
          this.getDetail();
        }

        this.option1 = await this.$store.dispatch('getDicOptionsList', 'unit.specs');

        this.option2 = await this.$store.dispatch('getDicOptionsList', 'unit.of.measurement');
        this.option3 = await this.$store.dispatch('getDicOptionsList', 'agriculture.resource.type');
      }
    }
  },
  methods: {
    getDetail() {
      this.form = _.cloneDeep(this.currentItem);

      this.form.recommendedPrice = this.form.recommendedPrice / 100;
      this.option1.forEach((e) => {
        if (e.key == this.currentItem.unitSpecs) {
          this.form.unitSpecs = e.id;
        }
      });
      this.option2.forEach((e) => {
        if (e.key == this.currentItem.unitOfMeasurement) {
          this.form.unitOfMeasurement = e.id;
        }
      });
      this.option3.forEach((e) => {
        if (e.key == this.currentItem.resourceType) {
          this.form.resourceType = e.id;
        }
      });
    },
    onSubmit() {
      let toastList = [];
      if (!this.form.name) {
        toastList.push('请输入品类名称');
      }
      if (!this.form.recommendedPrice) {
        toastList.push('请输入预计采购价');
      }
      if (!this.form.resourceType) {
        toastList.push('请选择品类');
      }
      if (!this.form.unitOfMeasurement) {
        toastList.push('请选择规格');
      }
      if (!this.form.unitSpecs) {
        toastList.push('请选择单位');
      }
      if (!this.form.countPerUnit) {
        toastList.push('请输入单位数量');
      }
      // if (!this.form.descriptions) {
      //   toastList.push('请输入描述');
      // }
      if (toastList.length) {
        this.$message({
          message: toastList.join(','),
          type: 'warning'
        });
        return;
      }

      const form = _.cloneDeep(this.form);
      form.recommendedPrice = this.form.recommendedPrice * 100;
      if (this.isEdit) {
        editProduction(form)
          .then((res) => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.closeMe();
            this.updateList();
          })
          .catch((error) => {});
      } else {
        addProduction(form)
          .then((res) => {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.closeMe();
            this.updateList();
          })
          .catch((error) => {});
      }
    },
    updateList() {
      this.$emit('updateList');
    },
    closeMe() {
      this.$emit('update:dialogVisible', false);
    }
  }
};
</script>
<style scoped lang="scss"></style>

<template>
  <el-dialog :title="title" v-if="dialogVisible" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <mapContainerVue :mapVisible.sync="mapVisible" @select="mapSelect"></mapContainerVue>

    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item :label="title + '名称'" prop="title">
        <el-input v-model="form.title" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item v-if="type == 'technology'" label="类型" prop="basicType">
        <el-select v-model="form.basicType" placeholder="请选择" :disabled="isDisable">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="type != 'technology'" label="机器类型" prop="subType">
        <el-select v-model="form.subType" placeholder="请选择" :disabled="isDisable">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="type != 'technology'" label="使用类型" prop="useType">
        <el-select v-model="form.useType" placeholder="请选择" :disabled="isDisable">
          <el-option v-for="item in useTypeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择地址" prop="mapInfo">
        <el-row>
          <div v-if="mapInfo"> {{ mapInfo.pname }}/{{ mapInfo.cityname }}/{{ mapInfo.adname }}/{{ mapInfo.address }} </div>
          <el-button :disabled="isDisable" type="primary" @click="mapVisible = true">选择地址</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="主题图片" prop="fileList">
        <el-upload
          :class="{ hideUpload: hideUploadEdit }"
          :disabled="isDisable"
          ref="upload"
          action=""
          :file-list="fileList"
          :limit="1"
          list-type="picture-card"
          accept="image/jpg,image/jpeg,image/png"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="fileChange"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="服务方" prop="serviceOrgName">
        <el-input v-model="form.serviceOrgName" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contractName">
        <el-input v-model="form.contractName" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contractPhone">
        <el-input v-model="form.contractPhone" :disabled="isDisable"></el-input>
      </el-form-item>

      <quill-editor class="editor" :options="editorOption" :content="content" :disabled="isDisable" @change="onEditorChange" />
      <div style="height: 100px"></div>
      <el-form-item>
        <el-button v-if="!isDisable" type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="closeMe()">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import dayjs from 'dayjs';

import { quillEditor } from 'vue-quill-editor';
import mapContainerVue from './mapContainer.vue';
import { createTechnology, serviceDetail1, uploadTechnology, createMachine, uploadMachine, createUav, uploadUav } from '../api/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: '',
    isDisable: false,
    type: ''
  },
  components: {
    quillEditor,
    mapContainerVue
  },
  computed: {
    title() {
      if (this.type == 'technology') {
        return '科技服务';
      } else if (this.type == 'machine') {
        return '农机服务';
      } else if (this.type == 'uav') {
        return '无人机服务';
      }
    }
  },
  data() {
    return {
      hideUploadEdit: false,
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ]
        },
        placeholder: '请输入正文'
      },

      rules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        serviceOrgName: [{ required: true, message: '请输入举办方', trigger: 'blur' }],
        mapInfo: [{ required: true }],
        basicType: [{ required: true }],
        useType: [{ required: true }],
        subType: [{ required: true }],
        fileList: [{ required: true }],
        contractName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contractPhone: [
          {
            required: true,
            message: '请输入正确联系电话',
            validator: this.validatePhone,
            trigger: 'blur'
          }
        ],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        startDate: [
          {
            validator: this.validateStartDate,
            required: true
          }
        ],
        applyStartDate: [
          {
            validator: this.validateApplyStartDate,
            required: true
          }
        ]
      },
      fileList: [],
      startDate: [],
      applyStartDate: [],
      mapVisible: false,
      content: '',
      richText: '',
      form: {
        title: '',
        address: '',
        serviceOrgName: '',
        contractPhone: '',
        contractName: '',
        basicType: '',
        subType: '',
        useType: ''
      },
      mapInfo: '',
      detailRes: {},
      options: [],
      useTypeOptions: []
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.hideUploadEdit = false;
        (this.fileList = []), (this.startDate = []), (this.applyStartDate = []), (this.mapVisible = false), (this.content = ''), (this.richText = '');
        (this.form = {
          title: '',
          address: '',
          serviceOrgName: '',
          contractPhone: '',
          contractName: '',
          subType: '',
          useType: ''
        }),
          (this.mapInfo = '');
        this.getDicList();

        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    validatePhone(rule, value, callback) {
      let msg = '';
      value = value && value.toString().replace(/\s+/g, '');
      if (!value) {
        msg = '请输入手机号码！';
      } else {
        let isMob = /^[0-9]+.?[0-9]*/;
        if (!isMob.test(value)) {
          msg = '请输入正确的电话号码！';
        }
      }
      if (msg) {
        callback(new Error(msg));
      } else {
        callback();
      }
    },

    async getDicList() {
      let key = 'business.tech.type';
      if (this.type == 'machine') {
        key = 'machine.type';
      } else if (this.type == 'uav') {
        key = 'uav.type';
      }

      this.options = await this.$store.dispatch('getDicOptionsList', key);
      this.useTypeOptions = await this.$store.dispatch('getDicOptionsList', 'machine.purpose.type');
    },
    getDetail() {
      console.log(this.type);
      let url = '';
      if (this.type == 'technology') {
        url = '/api/operate/govserv/technical/view';
      } else if (this.type == 'machine') {
        url = '/api/operate/govserv/machine/view';
      } else if (this.type == 'uav') {
        url = '/api/operate/govserv/uav/view';
      }
      serviceDetail1({ id: this.currentId }, url)
        .then((res) => {
          this.detailRes = res;
          (this.mapVisible = false), (this.content = res.content), (this.richText = '');
          this.form = {
            title: res.title,
            address: res.address,
            serviceOrgName: res.serviceOrgName,
            contractPhone: res.contractPhone,
            contractName: res.contractName,
            basicType: Number(res.basicType),
            subType: res.subType,
            useType: res.useType
          };
          this.fileList = [{ name: '1', url: res.titleImgUrl }];
          if (this.fileList && this.fileList.length) {
            this.hideUploadEdit = true;
          }
          let mapI = {
            pname: res.province,
            location: {
              lng: res.lon,
              lat: res.lat
            },
            cityname: res.city,
            adname: res.county
          };
          this.mapInfo = mapI;
        })
        .catch((error) => {});
    },
    mapSelect(e) {
      this.mapVisible = false;
      if (e) {
        this.mapInfo = e;
        this.form.address = e.name;
      }
    },
    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.fileList = fileList;
    },
    closeMe(done) {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      let toastList = [];
      let isMob = /^[0-9]+.?[0-9]*/;
      if (!isMob.test(this.form.contractPhone)) {
        toastList.push('请输入正确的电话号码！');
      }
      if (!this.form.title) {
        toastList.push('请输入标题');
      }
      if (!this.form.serviceOrgName) {
        toastList.push('请输入举办方');
      }
      if (this.type == 'technology' && !this.form.basicType) {
        toastList.push('请选择类型');
      }

      if (!this.form.contractName) {
        toastList.push('请输入联系人');
      }
      if (!this.form.contractPhone) {
        toastList.push('请输入联系电话');
      }
      if (!this.form.address) {
        toastList.push('请输入地址');
      }
      if (!this.mapInfo) {
        toastList.push('请选择地址');
      }
      if (!this.richText.html) {
        toastList.push('请输入内容');
      }
      if (!this.fileList || this.fileList.length == 0) {
        toastList.push('请选择图片');
      }
      if (toastList.length) {
        this.$message({
          message: toastList.join(','),
          type: 'warning'
        });
        return;
      }

      let params = { ...this.form };
      params.content = this.richText.html;
      params.province = this.mapInfo.pname;
      params.city = this.mapInfo.cityname;
      params.county = this.mapInfo.adname;
      params.lon = this.mapInfo.lng;
      params.lat = this.mapInfo.lat;

      let formData = new FormData();
      if (this.fileList && this.fileList.length && this.fileList[0].raw) {
        formData.append('file', this.fileList[0].raw);
      }
      formData.append('requestJson', JSON.stringify({ ...this.detailRes, ...params }));

      if (this.isEdit) {
        if (this.type == 'technology') {
          uploadTechnology(formData)
            .then((e) => {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        } else if (this.type == 'machine') {
          uploadMachine(formData)
            .then((e) => {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        } else if (this.type == 'uav') {
          uploadUav(formData)
            .then((e) => {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        }
      } else {
        if (this.type == 'technology') {
          createTechnology(formData)
            .then((e) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        } else if (this.type == 'machine') {
          createMachine(formData)
            .then((e) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        } else if (this.type == 'uav') {
          createUav(formData)
            .then((e) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        }
      }
    },
    onEditorChange(e) {
      this.richText = e;
    },
    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 300px;
}
</style>
